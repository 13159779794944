import styled from "styled-components"
import tw from "twin.macro"
import {colors, fontSize, size} from "../../../styles/theme"

export const NewLayoutFooter = styled.div`
  ${tw`flex justify-between items-center bg-black w-full`}
  flex-wrap: wrap;
  padding: 62px 65px;
  padding-bottom: ${size(28)};
  
  @media (max-width: 1500px) {
      ${tw`flex-col`}
      padding: ${size(35)} ${size(32)};
  }

  /* Extra Large (xl) */
  @media (max-width: 480px) {
    padding: ${size(42)} ${size(16)};
  }
`

export const NewLayoutFooterLeft = styled.div`
  ${tw`flex justify-between items-center`}
  width: 50%;

  @media (max-width: 1500px) {
    ${tw`w-full justify-start flex-col items-start`}
  }
`

export const NewLayoutFooterRight = styled.div`
  width: 50%;
  ${tw`flex items-end flex-col`}

  @media (max-width: 1500px) {
    ${tw`w-full`}
  }
`

export const NewLayoutFooterLogo = styled.a`
  figure {
    width: ${size(121)};
    margin-bottom: 0;
  }

  @media (max-width: 1500px) {
    margin-bottom: ${size(24)};
  }
`

export const NewLayoutFooterLinksGroup = styled.div`
  grid-template-columns: repeat(3, minmax(160px, 1fr));
  column-gap: 4rem;
  display: grid;
  row-gap: 0;
  margin-left: ${size(100)};

  @media (max-width: 1500px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`

export const NewLayoutFooterLink = styled.a`
  ${tw`text-white `}
  ${fontSize(14)}
  line-height: ${size(20)}
  
  @media (max-width: 768px) {
    &:nth-of-type(3n) {
      margin-bottom: ${size(24)};
    }
  }
`

export const NewLayoutFooterIcon = styled.figure`
  width: ${size(89)};
  margin-bottom: ${size(20)};
`

export const NewLayoutSocialGroup = styled.div`
  ${tw`flex items-center`}
`

export const NewLayoutSocialLabel = styled.div`
  ${tw`text-white `}
  ${fontSize(14)}
  margin-right: ${size(12)}
`

export const NewLayoutSocialIcons = styled.div`
  ${tw`flex items-center`}

  a {
    margin: 0 10px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      width: 35px;
      height: 35px;
      max-width: 35px;

      &:hover {
        color: ${colors.white};
      }
    }
  }
`

export const NewLayoutCopyright = styled.div`
  width: 100%;
  margin-top: ${size(44)};
  ${tw`text-white text-center italic`};
  ${fontSize(14)};
`
